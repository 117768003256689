import React, { useState, useEffect, useCallback } from 'react';
import './mapcomp.css';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import OSM from 'ol/source/OSM'; // Правильный импорт для OSM
import { LineString } from 'ol/geom'; // Правильный импорт для LineString
import { Style, Circle, Stroke } from 'ol/style';
import { fromLonLat } from 'ol/proj';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import AWS from 'aws-sdk';
import { useTranslation } from 'react-i18next';

    
    const MapComponent = ({ deviceNumber, setDeviceNumber, startDate, setStartDate, endDate, setEndDate }) => {
        const [map, setMap] = useState(null);
        const [data, setData] = useState([]);
        const [lastCoordinate, setLastCoordinate] = useState(null);
        const [zoomLevel, setZoomLevel] = useState(10); // Начальный масштаб
        const [searchClicked, setSearchClicked] = useState(false); // Состояние для отслеживания нажатия кнопки поиска
        const { t } = useTranslation();
    // Устанавливаем учетные данные AWS
    AWS.config.update({
        accessKeyId: 'AKIATCKAQSGYNSE5LOGG',
        secretAccessKey: '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',  //read_only access
        region: 'eu-north-1' // Укажите регион вашей таблицы DynamoDB
    });
    
        const dynamoDB = new AWS.DynamoDB.DocumentClient();
    
        const fetchDataWithDates = useCallback(async () => {
            if (!deviceNumber || !startDate || !endDate) return;
    
            const params = {
                TableName: 'gps_tab2',
                KeyConditionExpression: '#dn = :device_num AND #dt BETWEEN :start_dt AND :end_dt',
                ExpressionAttributeNames: {
                    '#dn': 'device_num',
                    '#dt': 'datetime'
                },
                ExpressionAttributeValues: {
                    ':device_num': deviceNumber,
                    ':start_dt': `${startDate}T00:00:00`,
                    ':end_dt': `${endDate}T23:59:59`
                }
            };
    
            try {
                const response = await dynamoDB.query(params).promise();
                if (response.Items.length > 0) {
                    const coordinatesData = response.Items.map(item => {
                        const coordinates = item.GPS.split(',').map(parseFloat);
                        return {
                            ...item,
                            coordinates: [coordinates[1], coordinates[0]]
                        };
                    });
                    setData(coordinatesData);
                    localStorage.setItem('routeData', JSON.stringify(coordinatesData));
    
                    const lastItem = coordinatesData[coordinatesData.length - 1];
                    setLastCoordinate(lastItem.coordinates);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }, [deviceNumber, startDate, endDate]);
    
        const handleDeviceNumberChange = (event) => {
            setDeviceNumber(event.target.value);
            localStorage.setItem('deviceNumber', event.target.value);
        };
    
        const handleStartDateChange = (event) => {
            setStartDate(event.target.value);
        };
    
        const handleEndDateChange = (event) => {
            setEndDate(event.target.value);
        };
    
        const handleSearchClick = () => {
            setSearchClicked(true);
        };
    
        useEffect(() => {
            const mapInstance = new Map({
                target: 'map',
                layers: [
                    new TileLayer({
                        source: new OSM()
                    })
                ],
                view: new View({
                    center: fromLonLat([20.861, 52.0902]),
                    zoom: zoomLevel
                })
            });
    
            mapInstance.getView().on('change:resolution', () => {
                setZoomLevel(mapInstance.getView().getZoom());
            });
    
            setMap(mapInstance);
    
            return () => mapInstance.setTarget(null);
        }, [zoomLevel]);
    
        useEffect(() => {
            if (searchClicked) {
                fetchDataWithDates();
                setSearchClicked(false);
            }
        }, [searchClicked, fetchDataWithDates]);
    
        useEffect(() => {
            if (map && data.length > 0) {
                const routeCoordinates = data.map(item => item.coordinates);
    
                const routeFeatures = routeCoordinates.map(coord => new Feature({
                    geometry: new Point(fromLonLat(coord))
                }));
    
                const routeVectorSource = new VectorSource({
                    features: routeFeatures
                });
    
                const routeStyle = new Style({
                    image: new Circle({
                        radius: 5,
                        fill: null,
                        stroke: new Stroke({
                            color: 'red',
                            width: 2
                        })
                    })
                });
    
                const routeVectorLayer = new VectorLayer({
                    source: routeVectorSource,
                    style: routeStyle
                });
    
                map.addLayer(routeVectorLayer);
    
                const routeLines = routeCoordinates.slice(1).map((endPoint, i) => {
                    const startPoint = routeCoordinates[i];
                    return new Feature({
                        geometry: new LineString([startPoint, endPoint])
                    });
                });
    
                const routeLineSource = new VectorSource({
                    features: routeLines
                });
    
                const routeLineStyle = new Style({
                    stroke: new Stroke({
                        color: 'blue',
                        width: 2
                        })
                });
    
                const routeLineLayer = new VectorLayer({
                    source: routeLineSource,
                    style: routeLineStyle
                });
    
                map.addLayer(routeLineLayer);
    
                if (lastCoordinate) {
                    const lastLonLat = fromLonLat(lastCoordinate);
                    map.getView().setCenter(lastLonLat);
                    map.getView().setZoom(zoomLevel);
                }
            }
        }, [map, data, lastCoordinate, zoomLevel]);
    
       
        return (
            <div className="container"> 
              <div id="map" style={{ marginRight: '10px', marginBottom: '5px', marginTop: '15px' }} ></div>
              <div id="buttons"  style={{ display: 'flex', flexDirection: 'column' ,  marginTop: '15px' }}>
              {t('device_num1')} <br/>
              {t('device_num2')}
                <input
                  type="text"
                  value={deviceNumber}
                  onChange={handleDeviceNumberChange}
                  placeholder="Введите номер устройства"
                  style={{ marginBottom: '10px' }}
                />
                {t('first_date')}
                
                <input
                  type="date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  placeholder="Введите начальную дату"
                  style={{ marginBottom: '10px' }}
                />
                {t('second_date')}
                <input
                  type="date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  placeholder="Введите конечную дату"
                  style={{ marginBottom: '10px' }}
                />
                <button onClick={handleSearchClick}>{t('search')}</button>
              </div>
            </div>
          );
          

        
    };
    
    export default MapComponent;
       