import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './i18n';
import { useTranslation } from 'react-i18next';
import i18n from './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const changeLanguage = (lang) => {
    localStorage.setItem('language', lang);
    i18n.changeLanguage(localStorage.getItem('language')); }


  return (
    <div>
      <button onClick={() => changeLanguage('en')}>EN</button>
      <button onClick={() => changeLanguage('ru')}>RU</button>
      <button onClick={() => changeLanguage('pl')}>PL</button>
      <button onClick={() => changeLanguage('by')}>BY</button>
      <button onClick={() => changeLanguage('az')}>AZ</button>
      <button onClick={() => changeLanguage('ge')}>GE</button>
      <button onClick={() => changeLanguage('uk')}>UK</button>
    </div>
  );
}
const savedLanguage = localStorage.getItem('language');
if (savedLanguage) {
    i18n.changeLanguage(savedLanguage);
}

root.render(
  <React.StrictMode>
    <LanguageSwitcher />
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
